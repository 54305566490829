import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admission-procedure',
  templateUrl: './admission-procedure.component.html',
  styleUrls: ['./admission-procedure.component.scss']
})
export class AdmissionProcedureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0)
  }

}
