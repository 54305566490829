import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-school-curriculum',
  templateUrl: './school-curriculum.component.html',
  styleUrls: ['./school-curriculum.component.scss']
})
export class SchoolCurriculumComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0)
  }

}
