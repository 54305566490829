import { Component, OnInit  } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare var $ : any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'saptagiri';
  router: any;
  ngOnInit() {
    window.scroll(0,0);

    $("#enquiryModal").modal('show');

    $('#enquiryForm').on('submit', function(event) {
      event.preventDefault(); // prevent reload
      
      var formData = new FormData(this);
      formData.append('service_id', 'gmail');
      formData.append('template_id', 'enquiry_form');
      formData.append('user_id', 'user_BInQbvjRZ49oreGR3ddU3');
      // formData.append('user_id', 'user_BInQbvjRZ49oreGR3ddU3');

      $.ajax('https://api.emailjs.com/api/v1.0/email/send-form', {
          type: 'POST',
          data: formData,
          contentType: false, // auto-detection
          processData: false // no need to parse formData to string
      }).done(function() {
          $("#enquiryModal").modal('hide');
          $("#enquirySuccessModal").modal('show');
      }).fail(function(error) {
          alert('Oops... ' + JSON.stringify(error));
      });
    });
}

}
